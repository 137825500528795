import React from "react";
import { Col, Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import VisuelHomeDevenirLoueurs from '../images/home/VISUEL_HOME_SOLUSPOTS_VIE_CHANTIER.png';
import VisuelHomeTransporteur from '../images/home/VISUEL_HOME_SOLUSPOTS_TRANSPORTEUR_BETONNIER_VERIFIES.png';
import PictoDemarcheEnvironnementale from '../images/home/picto-demarche-environnementale.png';
import PictoRecherche from '../images/home/picto-recherche.png';
import PictoInscription from '../images/home/picto-inscription.png';
import PictoContact from '../images/home/picto-contact.png';
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import BgBanner from '../images/home/bg-banner.jpg';
import Partner2 from '../images/partners/dhimitri-partner.png';
import Partner4 from '../images/partners/spartes-partner.png';
import Partner5 from '../images/partners/SNBPE-partner.webp';
import Partner6 from '../images/partners/ubiwan-partner.png';
import IllustrationBanner from '../images/home/illustration-banner.png';
import '@splidejs/react-splide/css';
import SearchFilters from "../components/SearchFilters";
import { Capacitor } from "@capacitor/core";
import Prices from "../components/Prices";
import MetaTags from 'react-meta-tags';

function HomePage() {
    return (
        <>
            <MetaTags>
                <title>Plateforme de mise en relation transporteurs-bétonniers | SoluSpots</title>
                <meta name="description" content="SoluSpots, la plateforme leader pour connecter transporteurs et bétonniers. Gagnez du temps, optimisez la gestion de vos camions et simplifiez le transport de béton. Découvrez nos services dès maintenant !"/>
            </MetaTags>
            <div className="home-section-banner" style={{ backgroundImage: `url(` + BgBanner + `)` }}>
                <div className="mediumcontainer">
                    <h1 className="home-section-banner-title">
                        <span className='banner-title-colored'>SoluSpots,</span><br /> la première plateforme d'annonces <br /> qui facilite la <span className='banner-title-colored'>livraison de béton&nbsp;!</span>
                    </h1>
                    <SearchFilters />
                    <div className="home-section-banner-reassurance">
                        <div className="home-section-banner-reassurance-item">
                            <p className="home-section-banner-reassurance-item-title">Réservation simple <br /> et rapide</p>
                        </div>
                        <div className="home-section-banner-reassurance-separator" />
                        <div className="home-section-banner-reassurance-item">
                            <p className="home-section-banner-reassurance-item-title">Camions disponibles <br /> dans toute la France</p>
                        </div>
                        <div className="home-section-banner-reassurance-separator" />
                        <div className="home-section-banner-reassurance-item">
                            <p className="home-section-banner-reassurance-item-title">Tous types de<br />véhicules référencés</p>
                        </div>
                        <div className="home-section-banner-reassurance-separator" />
                        <div className="home-section-banner-reassurance-item">
                            <p className="home-section-banner-reassurance-item-title">Chantiers <br /> partout en France</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <Row>
                    <Col xs={12} className='my-auto'>
                        <div className='text-center mt-5 mb-5'>
                            <h2 className='home-section-desc-content-title text-center'>SoluSpots vous <span>facilite la vie de chantier !</span></h2>
                            <p className="home-section-desc-content-block-desc">La plateforme SoluSpots est votre alliée pour simplifier et améliorer la gestion du transport de béton, que vous soyez transporteur ou bétonnier&nbsp;!</p>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='bigcontainer mb-5'>
                <Row>
                    <Col xl={2} xs={12} className='text-center'>
                    </Col>
                    <Col xl={8} xs={12} className='text-center'>
                        <img src={IllustrationBanner} />
                    </Col>
                    <Col xl={2} xs={12} className='text-center'>
                    </Col>
                </Row>
            </div>

            <div className="container">
                <Row>
                    <Col xs={12} className='my-auto'>
                        <Row>
                            <Col xs={12} xl={6}>
                                <div className='home-section-desc-content-block'>
                                    <h2 className="home-section-desc-content-title">Vous êtes transporteur ?</h2>
                                    <p class="home-section-steps-text"> <strong>Maximisez</strong> vos revenus grâce à <strong>SoluSpots</strong> !</p>
                                    <div className="home-section-desc-content-block-checkblock">
                                        <FontAwesomeIcon className="home-section-desc-content-block-checkblock-icon" icon={faCheck} />
                                        <p className="home-section-desc-content-block-text"><strong>Trouvez</strong> facilement des <strong>chantiers</strong> de <strong>bétonnage</strong> proches de chez vous<br/> et <strong>louez</strong> vos <strong>véhicules </strong>
                                         en toute simplicité.</p>
                                    </div>
                                    <div className="home-section-desc-content-block-checkblock">
                                        <FontAwesomeIcon className="home-section-desc-content-block-checkblock-icon" icon={faCheck} />
                                        <p className="home-section-desc-content-block-text"><strong>Créez</strong> vos <strong>annonces</strong> en quelques clics et <strong>mettez en relation</strong> votre flotte<br/> avec des <strong>bétonniers</strong>
                                        en quête de <strong>transporteurs</strong> fiables.</p>
                                    </div>
                                    <div className="home-section-desc-content-block-checkblock">
                                        <FontAwesomeIcon className="home-section-desc-content-block-checkblock-icon" icon={faCheck} />
                                        <p className="home-section-desc-content-block-text"><strong>SoluSpots</strong>, c'est la solution idéale pour <strong>rentabiliser</strong> vos <strong>camions-toupie</strong> <br/> et <strong>développer</strong> votre
                                        activité.</p>
                                    </div>
                                </div>
                                <div className="mt-4 mb-4">
                                    {Capacitor.getPlatform() != 'ios' ?
                                        <span className="solu-btn-outline" href="/inscription" bis_skin_checked="1">À partir de 3,99€ HT/jour</span>
                                    : ''}
                                </div>
                                {Capacitor.getPlatform() != 'ios' ?
                                    <a className="solu-btn solu-btn-primary mb-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                                : ''}
                            </Col>
                            <Col xs={12} xl={6}>
                                <div className='home-section-desc-content-block'>
                                    <h2 className="home-section-desc-content-title">Vous êtes bétonnier ?</h2>
                                    <p class="home-section-steps-text"><strong>Simplifiez</strong> votre quotidien avec <strong>SoluSpots</strong> !</p>
                                    <div className="home-section-desc-content-block-checkblock">
                                        <FontAwesomeIcon className="home-section-desc-content-block-checkblock-icon" icon={faCheck} />
                                        <p className="home-section-desc-content-block-text"> <strong>Trouvez</strong> rapidement des <strong>transporteurs</strong> disponibles pour vos <strong>chantiers</strong>.</p>
                                    </div>
                                    <div className="home-section-desc-content-block-checkblock">
                                        <FontAwesomeIcon className="home-section-desc-content-block-checkblock-icon" icon={faCheck} />
                                        <p className="home-section-desc-content-block-text"><strong>Créez</strong> vos <strong>annonces</strong> de <strong>besoins en béton</strong> en quelques clics et <strong>mettez-vous en relation</strong> avec
                                        des <strong>transporteurs</strong> fiables.</p>
                                    </div>
                                    <div className="home-section-desc-content-block-checkblock">
                                        <FontAwesomeIcon className="home-section-desc-content-block-checkblock-icon" icon={faCheck} />
                                        <p className="home-section-desc-content-block-text"> <strong>SoluSpots</strong>, c'est la solution pour <strong>optimiser</strong> votre <strong>organisation</strong> et <strong>réduire</strong> vos délais.</p>
                                    </div>
                                </div>
                                {Capacitor.getPlatform() != 'ios' ?
                                    <div className="mt-4 mb-4">
                                        <span className="solu-btn-outline" href="/inscription" bis_skin_checked="1">Gratuit</span>
                                    </div>
                                : ''}
                                {Capacitor.getPlatform() != 'ios' ?
                                    <a className="solu-btn solu-btn-primary mb-4" href="/inscription" bis_skin_checked="1">Je m'inscris gratuitement</a>
                                : ''}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="bigcontainer">
                <div className="home-section-video">
                    <Container>
                        <h2><span>SoluSpots</span> en vidéo</h2>
                        <iframe src="https://www.youtube.com/embed/nY8O7ywB6zs?rel=0" title="SoluSpots - La plateforme qui facilite la vie de chantier !" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        {Capacitor.getPlatform() != 'ios' ?
                            <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                        : ''}
                    </Container>
                </div>
            </div>

            <div className="bigcontainer">
                <div className="home-section-steps">
                    <Container>
                        <h2>Comment ça <span>marche ?</span></h2>
                        <Row>
                            <Col xs={12} lg={8}>
                                <p className='home-section-steps-text'>
                                SoluSpots, c'est simple et rapide :<br/>
                                Vous êtes transporteur ? Louez votre véhicule en quelques clics.<br/>
                                Vous êtes bétonnier ? Trouvez le transporteur idéal pour votre chantier.<br/>
                                Le tout en 3 étapes clés pour une mise en relation efficace.<br/>
                                </p>
                            </Col>
                            <Col xs={12} lg={4} className="home-section-steps-link">
                                {Capacitor.getPlatform() != 'ios' ?
                                    <a className="solu-btn solu-btn-primary" href="/inscription">Je m'inscris</a>
                                : ''}
                            </Col>
                        </Row>
                        <Row className="mt-5 gx-5 justify-content-center">
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="home-section-steps-cards">
                                    <span className="home-section-steps-cards-number">01</span>
                                    <img className="home-section-steps-cards-img" src={PictoInscription} />
                                    <p className="home-section-steps-cards-title"><br/><br/>Inscrivez-vous<br/></p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="home-section-steps-cards">
                                    <span className="home-section-steps-cards-number">02</span>
                                    <img className="home-section-steps-cards-img" src={PictoRecherche} />
                                    <p className="home-section-steps-cards-title">Déposez ou consultez<br /> les annonces de camions<br />ou de chantiers</p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="home-section-steps-cards">
                                    <span className="home-section-steps-cards-number">03</span>
                                    <img className="home-section-steps-cards-img" src={PictoContact} />
                                    <p className="home-section-steps-cards-title">Prenez contact<br/>directement via la <br/>messagerie SoluSpots</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='home-section-rental'>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6} xl={5} className='my-auto'>
                                <img src={VisuelHomeTransporteur} alt="Camion loueurs SoluSpot" />
                            </Col>
                            <Col xs={12} lg={6} xl={5} className='my-auto'>
                                <div className='home-section-rental-content'>
                                    <h2 className='home-section-rental-content-title'>100% des transporteurs <br /> et des bétonniers <br/> sont <span>évalués et vérifiés</span></h2>
                                    <div className='home-section-rental-content-block'>
                                        <p className="home-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} />Documents légaux aux normes</p>
                                        <p className="home-section-rental-content-block-text">Les membres de SoluSpots sont vérifiés et doivent fournir un extrait Kbis aux normes. Cette vérification permet de s’assurer de la fiabilité et de la légalité des entreprises inscrites sur la plateforme.</p>
                                    </div>
                                    <div className='home-section-rental-content-block'>
                                        <p className="home-section-rental-content-block-subtitle"> <FontAwesomeIcon icon={faCheck} />Évaluation et recommandation par les entreprises</p>
                                        <p className="home-section-rental-content-block-text">Soluspots est une communauté collaborative qui fonctionne en réseau avec les transporteurs et les bétonniers. Chaque professionnel est évalué selon des critères de qualité et de performance pour garantir un service optimal.</p>
                                    </div>
                                    <div className='home-section-rental-content-block'>
                                        <p className="home-section-rental-content-block-subtitle"> <FontAwesomeIcon icon={faCheck} />Charte Soluspots signée</p>
                                        <p className="home-section-rental-content-block-text">Chaque inscrit sur SoluSpots.fr s’engage à respecter la charte de la plateforme, qui garantit notamment la qualité des camions.</p>
                                    </div>
                                </div>
                                <a className="solu-btn solu-btn-primary mt-3" href="/resultat">Je découvre toutes les annonces</a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="bigcontainer">
                {Capacitor.getPlatform() != 'ios' ?
                    <>
                        <div className="discover-section-price">
                            <Row>
                                <div className="discover-section-price-presentation">
                                    <h2 className="discover-section-price-presentation-title">Nos tarifs pour les transporteurs</h2>
                                    <p className="discover-section-price-presentation-subtitle">
                                        SoluSpots vous propose différents abonnements<br/>afin de répondre au mieux à vos besoins.
                                    </p>
                                </div>
                            </Row>

                            <Prices />
                        </div>
                    </>
                : ''}


                <div className="home-section-environnement">
                    <Container>
                        <Row>
                            <div className="home-section-environnement-content">
                                <img src={PictoDemarcheEnvironnementale} alt="Environnement - SoluSpot" />
                                <div className="home-section-environnement-content-block">
                                    <h2 className="home-section-environnement-content-block-title">Optimisez votre logistique béton et vos émissions de CO2 grâce à SoluSpots !</h2>
                                    <p className="home-section-environnement-content-block-subtitle">En rejoignant notre plateforme de mise en relation, vous trouvez rapidement le transporteur idéal pour vos livraisons de béton, réduisant ainsi vos coûts de transport et votre empreinte carbone.</p>
                                    {Capacitor.getPlatform() != 'ios' ?
                                        <a className="solu-btn solu-btn-primary mt-3" href="/inscription">Je veux rejoindre la communauté</a>
                                    : ''}
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>

                <div className="home-section-partners">
                    <Container>
                        <h2>Nos <span>partenaires</span></h2>
                        <p className='home-section-partners-text mt-3'>SoluSpots s'associe à des partenaires issus de différents secteurs d'activité pour améliorer <br/> et parfaire votre expérience de location.</p>
                        <Splide hasTrack={ false } aria-label="..."
                            options={ {
                                perPage: 3,
                                height : '150px',
                                rewind : true,
                                perMove: 1,
                                pagination: false,
                                autoplay: true,
                                breakpoints: {
                                    992: {
                                        perPage: 2,
                                    },
                                    576: {
                                        perPage: 1,
                                    },
                                  }
                            } }
                        >
                            <SplideTrack>
                                <SplideSlide>
                                    <a href="https://www.ubiwan.net/" target="_blank" rel="noopener"><img src={Partner6} alt='Ubiwan' /></a>
                                </SplideSlide>
                                <SplideSlide>
                                    <a href="https://www.dhimitribeton.com/" target="_blank" rel="noopener"><img src={Partner2} alt='Dhimitri Beton' /></a>
                                </SplideSlide>
                                <SplideSlide>
                                    <a href="https://www.spartes.fr/" target="_blank" rel="noopener"><img src={Partner4} alt='Spartes' /></a>
                                </SplideSlide>
                                <SplideSlide>
                                    <a href="https://www.snbpe.org/" target="_blank" rel="noopener"><img src={Partner5} alt='SNBPE' /></a>
                                </SplideSlide>
                            </SplideTrack>

                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev"><FontAwesomeIcon icon={faChevronRight} /></button>
                                <button className="splide__arrow splide__arrow--next"><FontAwesomeIcon icon={faChevronRight} /></button>
                            </div>
                        </Splide>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default HomePage;
